// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import crumb from 'packs/crumb';
import Rails from '@rails/ujs';
import { Turbo } from"@hotwired/turbo-rails"
import * as ActiveStorage from '@rails/activestorage';
import 'packs/cookie_consent';
import 'bootstrap.native';

Rails.start();
ActiveStorage.start();

document.addEventListener('turbo:load', function() {
  BSN.initCallback(document.body);

  crumb.drop();

  // Update file input label after selecting a file
  var inputs = document.getElementsByClassName('custom-file-input');
  for(var z = 0; z < inputs.length; z++) {
    inputs[z].addEventListener('change', function(e) {
      var files = [];
      for (var i = 0; i < e.target.files.length; i++) {
          files.push(e.target.files[i].name);
      }
      e.target.nextSibling.innerHTML = files.join(', ');
    });
  }
});
