document.addEventListener('turbo:before-cache', function(e) {
  document.getElementById("cookieAlert").classList.add("d-none");
});

document.addEventListener('turbo:load', function(e) {
  var cookieNameValue = 'hladni_val_cookie_consented=true';

  function setCookie() {
    var date = new Date();
    date.setFullYear(date.getFullYear() + 1);
    var secure = (location.protocol === "https:" ? ";secure" : "");

    document.cookie = cookieNameValue + ";expires=" + date.toUTCString() + ";path=/" + secure;
    document.getElementById("cookieAlert").classList.add("d-none");
  }

  if (document.cookie.indexOf(cookieNameValue) === -1) {
    document.getElementById("cookieAlert").classList.remove("d-none");

    var button = document.getElementById("acceptCookies");

    if (button.attachEvent) {
      button.attachEvent('onclick', setCookie);
    } else {
      button.addEventListener('click', setCookie, false);
    }
  }
});
